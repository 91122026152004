.outterBooking {
  color: #707070;
}

.destinationBookingNew {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: sticky;
}

.destinationBookingNew img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBookingNew span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.newJourneyBookingDiv {
  display: flex;
  margin-top: -0.5rem;
  flex-wrap: wrap;
  margin-bottom: -1rem;
}
.newJourneyBookingImg {
  width: 4rem;
  height: 4rem;
  float: left;
}

.newJourneyBooking {
  float: left;
  font-size: 20pt;
  margin-top: 0.7rem;
  margin-left: 0.5rem;
}

.bookingDiv {
  text-align: center;
  margin-top: -1.5rem;
}

.bookingMaxPriceNew {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBookingNew {
  display: inline-block;
  font-size: 20pt;
}

.payFormBookingNew {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 800px) and (min-height: 800px) {
  .destinationBookingNew img {
    width: 52px;
    height: 60px;
    margin-right: 0.5rem;
    float: left;
  }

  .destinationBookingNew span {
    font-size: 32pt;
    color: #707070;
    float: left;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  .arrivalBookingNew {
    margin-top: 4rem;
    font-size: 21pt;
    margin-left: 4.7rem;
  }

  .newJourneyBookingDiv {
    display: flex;
    margin-top: -0.5rem;
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }
  .newJourneyBookingImg {
    width: 8rem;
    height: 8rem;
    float: left;
  }

  .newJourneyBooking {
    float: left;
    font-size: 32pt;
    margin-top: 2.2rem;
    margin-left: 0.5rem;
  }

  .bookingMaxPriceNew {
    margin-top: -1.5rem;

    display: inline-block;
    font-size: 20pt;
  }

  .priceBookingNew {
    display: inline-block;
    font-size: 32pt;
  }

  .payFormBookingNew {
    margin-top: -0.5rem;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 0.5;
    font-size: 32pt;
  }

  .buttonBooking {
    width: 60%;
    height: 50pt;
    background-color: #57628d;
    border: none;
    color: white;
    font-size: 20pt;
    cursor: pointer;
    border-radius: 17pt;
    margin-top: 8%;
  }
}
