.map{
  width: 100%;
  -webkit-transition: 1s; /*Safari*/
  transition: 1s;
  background:
    url("../assets/Map2.png");
  position: fixed;
  top: 0px;
  background-size: cover;
  background-position: 50% 15%;
  background-repeat: no-repeat;
  text-align: center;
}
.arrivalTime {
  position: fixed;
  top: 0;
  left: -40px;
  background-color: rgba(57, 62, 141, 0.4);
  width: 200px;
  height: 64px;
  border-radius: 32px
}
.arrivalTimeSpan{
  position: relative;
  font-size: 14pt;
  color: #FFFFFF;
  text-align: left;
  margin: 12px;
  margin-left: 88px
}
.big{
  height: 90%
}
.small{
  height: 20%
}
