.MediaPicture {
  float: left;
  width: 126px ;
  height: 175px;
  margin: 1%;
  overflow-y: hidden;
  box-shadow: 5px 5px 3px #AAAAAA, 0 5px 5px 0px #AAAAAA;
}
.MediaPictureImg {
  width: 100%;
  margin-top: -30px
}
.MediaName{
  position: relative;
  top: 147px;
  background: rgba(255,255,255,0.8);
  padding: 5px;
}
