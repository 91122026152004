.container {
  text-align: left;
  color: #707070;
  font-size: 12pt;
  font-weight: lighter;
  width: 90%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 4%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.media {
  width: 100%;
  max-width: 720px;
}

.Timeline {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.MediaCoverContainer {
  width: 200%;
  height: 200px;
}

.SearchMedia {
  width: 60%;
  font-size: 12pt;
  padding: 6pt;
  border: none;
  box-shadow: 3px 3px 6px #AAAAAA, 0 3px 3px 0px #AAAAAA;;
}
.Lens{
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.MediaDescribtion{
  margin-top: 10%;
  height: 200px;
  width: 100%;
}
.coverInfos {
  font-size: 12pt;
  margin-right: 5%;
  float: right;
  text-align: right;
}
.toogleInvitation{
  margin-right: 2rem;
  float: left;
  vertical-align: middle;
}
h1 {
  text-align: center;
  color: #57628d;
  font-size: 20pt;
}
