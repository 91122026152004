/* .headerApp {
  background-color: #57628d;
  height: 4rem;
  border-style: solid;
  text-align: center;
  color: #818181;
}

.headerApp .headerContent {
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  margin-top: 0.9rem;
  position: sticky;
  color: white;
}

.headerApp .backButton {
  width: 20pt;
  height: 30px;
  float: right;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.headerApp .toProfile {
  width: 44px;
  height: 44px;
  float: right;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
} */

.invisible {
  visibility: hidden;
}

.body {
  background-color: white;
  text-align: center;
  color: #818181;
}

.destination {
  border-style: solid;
  border-color: #dddee0;
  border-radius: 17pt;
  border-width: 0.5pt;
  max-width: 16rem;
  height: 3.5rem;
  margin-left: 3%;
  margin-bottom: 0.5rem;
  width: 100%;
  margin-top: 1rem;
}

.destination img {
  margin-top: 1rem;
  float: left;
  width: 22px;
  height: 32px;
  margin-top: 0.8rem;
  margin-left: 1rem;
}

.destination span {
  margin-top: 0.9rem;
  margin-left: 0.5rem;
  float: left;
  font-size: 20pt;
  color: #707070;
}

@media only screen and (max-width: 272px) {
  .destination span {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    float: left;
    font-size: 20pt;
    color: #707070;
  }
}
