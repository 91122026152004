.mainSearch {
  height: 100vh;
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url("../assets/taxiBackground.png");
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}
input[disabled],
textarea[disabled],
select[disabled="disabled"] {
  -webkit-text-fill-color: #707070;
  -webkit-opacity: 1;
  color: #707070;
  background: #dbdcdb;
}
.toProfile {
  width: 44px;
  height: 44px;
  float: right;
  margin-right: 1rem;
  margin-top: 0.7rem;
}

.backButtonSearch {
  width: 20px;
  height: 30px;
  float: left;
  margin-left: 1rem;
  margin-top: 1.1rem;
}

.floatingContent {
  position: absolute;
  overflow: auto;
}

.contentSearchInner {
  max-height: 100vh;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  opacity: 0.85;

  overflow: auto;
}

.contentSearchInner p {
  font-size: 25pt;
  color: white;
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (orientation: portrait) {
  .proceedImg {
    float: right;
    width: 45pt;
    height: 45pt;
    margin-right: 5%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 45pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 22pt;
    margin-left: 5%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    color: #707070;
  }
}

@media only screen and (orientation: portrait) and (max-width: 365px) and (max-height: 749px) {
  .proceedImg {
    float: right;
    width: 36pt;
    height: 36pt;
    margin-right: 5%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 36pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 22pt;
    margin-left: 5%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;

    color: #707070;
  }
}

@media only screen and (orientation: portrait) and (max-width: 329px) and (max-height: 749px) {
  .contentSearchInner p {
    font-size: 22pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 26pt;
    height: 26pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 26pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (min-width: 548px) {
  .contentSearchInner p {
    font-size: 26pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 32pt;
    height: 32pt;
    margin-right: 5rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 32pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 5rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (min-width: 800px) {
  .contentSearchInner p {
    font-size: 26pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 32pt;
    height: 32pt;
    margin-right: 15%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 32pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 15%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (max-width: 548px) {
  .contentSearchInner p {
    font-size: 24pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 28pt;
    height: 28pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 28pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 18pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    color: #707070;
  }
}

@media only screen and (min-height: 580px) and (min-width: 750px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 48pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 66pt;
    height: 66pt;
    margin-right: 12%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 66pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 32pt;
    margin-left: 12%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (min-height: 750px) and (min-width: 1110px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 56pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 78pt;
    height: 78pt;
    margin-right: 6rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 78pt;
    width: 70%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 34pt;
    margin-left: 6rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (min-height: 750px) and (min-width: 1200px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 70pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 78pt;
    height: 78pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 78pt;
    width: 70%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 34pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}
