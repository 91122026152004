.headerApp {
  /*   background-color: #57628d;
  height: 4rem;
  border-style: solid;
  text-align: center;
  color: #818181; */
  z-index: 2
}

.headerContent {
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  position: sticky;
  color: white;
}

.headerApp .headerContent span {
  display: inline-block;
  margin-top: 1rem;
}

.headerApp .backButton {
  width: 16px;
  height: 24px;
  float: left;
  margin-left: 0.7rem;
  margin-top: 1.3rem;
}

.headerApp .toProfile {
  width: 36px;
  height: 36px;
  float: right;
  margin-right: 0.7rem;
  margin-top: 0.8rem;
}
