.outterProfile {
  color: #707070;
}

.pic {
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.profileImg {
  border: solid;
  border-color: #57628d;
  border-radius: 50%;
  height: 15rem;
}

.topProfileFlexContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: -2rem;
}

.bottomProfileFlexContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.profileTag {
  font-size: 18pt;
  margin-right: 0.5rem;
}

.profileProp {
  font-size: 14pt;
  margin-left: 0.5rem;
  margin-top: 1.5rem;
}

.interests {
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  margin: 10px;
  font-size: 18pt;
  color: #707070;
}

.interestsP {
  margin-left: 1rem;
}

.interestsContainerOutter {
  display: flex;
  align-items: center;
  justify-content: center;

  justify-items: center;
  text-align: center;

  align-content: center;
  flex-direction: row;
  font-size: 14pt;
  flex-wrap: wrap;
}

.interestContainer {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  flex-basis: 20%;
  text-align: center;
}

.interestImg {
  height: 4rem;
  width: 4rem;
}

.interestTag {
  margin-top: 0.5rem;
}

.addInterest {
  height: 24pt;
  width: 24pt;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 1.7rem;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .pic {
    border-color: #dddee0;
    border-width: 1pt;
    border-style: solid;
    text-align: center;
    margin: 15px;
    padding: 10px;
  }

  .profileImg {
    margin-top: 2rem;
    border: solid;
    border-color: #57628d;
    border-radius: 50%;
    height: 30rem;
  }

  .topProfileFlexContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: -4rem;
  }

  .bottomProfileFlexContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .profileTag {
    font-size: 34pt;
    margin-right: 1rem;
  }

  .profileProp {
    font-size: 28pt;
    margin-left: 1rem;
    margin-top: 2.6rem;
  }

  .interests {
    border-color: #dddee0;
    border-width: 1pt;
    border-style: solid;
    margin: 15px;
    font-size: 34pt;
    color: #707070;
  }

  .interestsP {
    margin-left: 2rem;
  }

  .interestsContainerOutter {
    display: flex;
    align-items: center;
    justify-content: center;

    justify-items: center;
    text-align: center;

    align-content: center;
    flex-direction: row;
    font-size: 28pt;
    flex-wrap: wrap;
  }

  .interestContainer {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    flex-basis: 20%;
    text-align: center;
  }

  .interestImg {
    height: 10rem;
    width: 10rem;
  }

  .interestTag {
    margin-top: 1rem;
  }

  .addInterest {
    height: 60pt;
    width: 60pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 5rem;
  }
}
