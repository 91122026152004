.firstService {
  margin-top: 2rem;
  border-width: 1px;
  height: 42pt;
  border-color: #a4a6a8;
  border-width: 1pt;
  float: left;
  width: 100%;
  border-top-style: solid;
  border-bottom-style: solid;
}

.service {
  border-width: 1px;
  height: 42pt;
  border-color: #a4a6a8;
  border-width: 1pt;
  float: left;
  width: 100%;
  border-bottom-style: solid;
}

.serviceString {
  margin-left: 2rem;
  margin-top: 14pt;
  float: left;
}

.toogleService {
  margin-right: 2rem;
  margin-top: 11.5pt;
  float: right;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .firstService {
    margin-top: 2rem;
    border-width: 1px;
    height: 66pt;
    border-color: #a4a6a8;
    border-width: 1pt;
    float: left;
    width: 100%;
    border-top-style: solid;
    border-bottom-style: solid;
  }

  .service {
    border-width: 1px;
    height: 66pt;
    border-color: #a4a6a8;
    border-width: 1pt;
    float: left;
    width: 100%;
    border-bottom-style: solid;
  }

  .serviceString {
    margin-left: 2rem;
    margin-top: 17pt;
    float: left;
  }

  .toogleService {
    margin-right: 2rem;
    margin-top: 16pt;
    float: right;
  }
}
