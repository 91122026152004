.mainAssistant {
  height: 100vh;
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url("../assets/taxiBackground.png");
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.floatingContent {
  position: absolute;
  overflow: auto;
}

.contentAssistantInner {
  max-height: 100vh;
  width: 80%;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  opacity: 0.85;

  overflow: auto;
}

.skipAssistant {
  font-size: 12pt;
  color: white;
  font-weight: lighter;
  text-align: right;
  margin-right: 1rem;
  margin-top: 1rem;
}

.contentAssistantBorder {
  border-style: solid;
  border-radius: 20px;
  border-color: white;
  background: white;
  margin-bottom: 2rem;
}

.topicAssistant {
  font-size: 13pt;
  color: #57628d;
  font-weight: 550;
}

.heartImg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 40pt;
  max-height: 40pt;
}

.assistantText {
  font-size: 12pt;
  color: #57628d;
  font-weight: lighter;
  max-width: 80%;
  margin-left: 10%;
}

.circleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 0.7rem;
  width: 0.7rem;
  border: 0px solid;
  border-radius: 100px;
  background: #bebdbd;
}

.filledCircle {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 0.7rem;
  width: 0.7rem;
  border: 0px solid;
  border-radius: 100px;
  background: #57628d;
}

@media only screen and (max-height: 421px) and (max-width: 820px) {
  .topicAssistant {
    font-size: 13pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 34pt;
    max-height: 34pt;
  }

  .assistantText {
    font-size: 11pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
}

@media only screen and (max-height: 400px) and (max-width: 648px) {
  .topicAssistant {
    font-size: 12pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 30pt;
    max-height: 30pt;
  }

  .assistantText {
    font-size: 11pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }

  .circleDiv {
    margin-top: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-height: 365px) and (max-width: 580px) {
  .topicAssistant {
    font-size: 11pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 28pt;
    max-height: 28pt;
  }

  .assistantText {
    font-size: 9pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
  .circleDiv {
    margin-top: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-height: 616px) and (max-width: 280px) {
  .topicAssistant {
    font-size: 12pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 32pt;
    max-height: 32pt;
  }

  .assistantText {
    font-size: 10pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
  .circleDiv {
    margin-top: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-height: 750px) and (min-width: 750px) {
  .topicAssistant {
    font-size: 26pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 52pt;
    max-height: 52pt;
  }

  .assistantText {
    font-size: 22pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }

  .skipAssistant {
    font-size: 22pt;
    color: white;
    font-weight: lighter;
    text-align: right;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .circle {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    border: 0px solid;
    border-radius: 100px;
    background: #bebdbd;
  }

  .filledCircle {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    border: 0px solid;
    border-radius: 100px;
    background: #57628d;
  }
}
