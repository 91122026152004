body {
  margin: 0;
  padding: 0;

  font-family: Helvetica, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
}

.mainSearch {
  height: 100vh;
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url(/static/media/taxiBackground.00647f56.png);
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}
input[disabled],
textarea[disabled],
select[disabled="disabled"] {
  -webkit-text-fill-color: #707070;
  -webkit-opacity: 1;
  color: #707070;
  background: #dbdcdb;
}
.toProfile {
  width: 44px;
  height: 44px;
  float: right;
  margin-right: 1rem;
  margin-top: 0.7rem;
}

.backButtonSearch {
  width: 20px;
  height: 30px;
  float: left;
  margin-left: 1rem;
  margin-top: 1.1rem;
}

.floatingContent {
  position: absolute;
  overflow: auto;
}

.contentSearchInner {
  max-height: 100vh;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.85;

  overflow: auto;
}

.contentSearchInner p {
  font-size: 25pt;
  color: white;
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (orientation: portrait) {
  .proceedImg {
    float: right;
    width: 45pt;
    height: 45pt;
    margin-right: 5%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 45pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 22pt;
    margin-left: 5%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    color: #707070;
  }
}

@media only screen and (orientation: portrait) and (max-width: 365px) and (max-height: 749px) {
  .proceedImg {
    float: right;
    width: 36pt;
    height: 36pt;
    margin-right: 5%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 36pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 22pt;
    margin-left: 5%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;

    color: #707070;
  }
}

@media only screen and (orientation: portrait) and (max-width: 329px) and (max-height: 749px) {
  .contentSearchInner p {
    font-size: 22pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 26pt;
    height: 26pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 26pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (min-width: 548px) {
  .contentSearchInner p {
    font-size: 26pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 32pt;
    height: 32pt;
    margin-right: 5rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 32pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 5rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (min-width: 800px) {
  .contentSearchInner p {
    font-size: 26pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 32pt;
    height: 32pt;
    margin-right: 15%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 32pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 14pt;
    margin-left: 15%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (orientation: landscape) and (max-width: 548px) {
  .contentSearchInner p {
    font-size: 24pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 28pt;
    height: 28pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 28pt;
    width: 66%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 18pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    color: #707070;
  }
}

@media only screen and (min-height: 580px) and (min-width: 750px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 48pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 66pt;
    height: 66pt;
    margin-right: 12%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 66pt;
    width: 60%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 32pt;
    margin-left: 12%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (min-height: 750px) and (min-width: 1110px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 56pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 78pt;
    height: 78pt;
    margin-right: 6rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 78pt;
    width: 70%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 34pt;
    margin-left: 6rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

@media only screen and (min-height: 750px) and (min-width: 1200px) {
  .toProfile {
    width: 66px;
    height: 66px;
    float: right;
    margin-right: 1rem;
    margin-top: 0.7rem;
  }

  .backButtonSearch {
    width: 40px;
    height: 55px;
    float: left;
    margin-left: 1rem;
    margin-top: 1.1rem;
  }

  .contentSearchInner p {
    font-size: 70pt;
    color: white;
  }

  .proceedImg {
    float: right;
    width: 78pt;
    height: 78pt;
    margin-right: 10%;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: -0.5rem;
  }

  .inputSearch {
    float: left;
    height: 78pt;
    width: 70%;
    border-radius: 10pt;
    border-width: 0.5pt;
    text-align: center;
    font-size: 34pt;
    margin-left: 10%;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: #707070;
  }
}

.headerApp {
  /*   background-color: #57628d;
  height: 4rem;
  border-style: solid;
  text-align: center;
  color: #818181; */
  z-index: 2
}

.headerContent {
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  position: -webkit-sticky;
  position: sticky;
  color: white;
}

.headerApp .headerContent span {
  display: inline-block;
  margin-top: 1rem;
}

.headerApp .backButton {
  width: 16px;
  height: 24px;
  float: left;
  margin-left: 0.7rem;
  margin-top: 1.3rem;
}

.headerApp .toProfile {
  width: 36px;
  height: 36px;
  float: right;
  margin-right: 0.7rem;
  margin-top: 0.8rem;
}

.outter {
  float: left;
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 94%;
  cursor: pointer;
}

.outter:hover,
.outter:focus {
  background: #e9edf1;
}

.arrival {
  text-align: left;
  float: left;
  width: 65%;
  margin-left: 5%;
  font-size: 12pt;
}

.price {
  float: right;
  text-align: right;
  width: 25%;
  margin-right: 5%;
  font-size: 12pt;
}
/*von hier*/

.personDiv {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}
.personDiv_2 {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 1rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}

.personDiv2 {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  align-items: normal;
  -webkit-align-items: normal;

  overflow: hidden;
  margin-bottom: 1rem;
}

.personInfos {
  display: flex;
  display: -webkit-flex;
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  -webkit-flex-wrap: wrap;

          flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personName {
  margin: 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 19pt;
}
.personMatching {
  margin: 3px 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 12pt;
}

.personAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

/*bis hier*/

.img {
  max-width: 4rem;
  height: 4rem;
}

.img_2 {
  width: 48pt;
  height: 48pt;
  float: left;
  margin-top: 5%;
  margin-left: 5%;
}

.watchingDiv {
  margin-left: 1.75rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0.6rem;
  margin-top: 4rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}

.watchingImg {
  width: 2.5rem;
  height: 2rem;
}
.watchingProp {
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  margin-top: 0rem;
  margin-left: 1.25rem;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 10pt;
  color: #57628d;
}

.watchingSuggestion {
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  margin-top: 0rem;
  margin-left: 1.25rem;
  -webkit-flex-basis: 90%;
          flex-basis: 90%;
  font-size: 10pt;
  color: #57628d;
}

.newJourneyImg {
  width: 4rem;
  height: 4rem;
  float: left;
  margin-left: 1rem;
  position: relative;
  margin: 0rem 1rem 1rem 1rem;
}

.newJourneyP {
  float: left;
  font-size: 20pt;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 357px) {
  .watchingDiv {
    margin-left: 1.75rem;
    display: flex;
    display: -webkit-flex;
    padding-top: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: -0.4rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.6rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 8pt;
    color: #57628d;
  }

  .personInfos {
    display: flex;
    display: -webkit-flex;
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    -webkit-flex-wrap: wrap;

            flex-wrap: wrap;
    margin-left: 0.5rem;
    margin-top: 0.3rem;
  }
}
@media only screen and (min-width: 627px) {
  .watchingDiv {
    margin-left: 1.75rem;
    display: flex;
    display: -webkit-flex;
    padding-top: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.4rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}
@media only screen and (min-width: 405px) {
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 336px) {
  .watchingImg {
    width: 2.5rem;
    height: 2rem;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 65%;
    margin-left: 5%;
    font-size: 10pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 5%;
    font-size: 10pt;
  }
}

@media only screen and (min-width: 750px) and (min-height: 720px) {
  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: -0.2rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 18pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 18pt;
    color: #57628d;
  }

  .personName {
    margin: 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 28pt;
  }
  .personMatching {
    margin: 3px 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 18pt;
  }

  .personAge {
    margin-left: 5px;
    font-size: 19pt;
  }

  /*bis hier*/

  .img {
    max-width: 6rem;
    height: 6rem;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 55%;
    margin-left: 10%;
    font-size: 18pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 10%;
    font-size: 18pt;
  }

  .watchingDiv {
    margin-left: 2.1rem;
    display: flex;
    display: -webkit-flex;
    margin-top: 5.5rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingImg {
    width: 3.5rem;
    height: 3rem;
  }

  .newJourneyImg {
    width: 6rem;
    height: 6rem;
    float: left;
    margin-left: 1rem;
    position: relative;
    margin: 0rem 1rem 1rem 1rem;
  }

  .newJourneyP {
    float: left;
    font-size: 28pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1360px) and (min-height: 1020px) {
  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 18pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 18pt;
    color: #57628d;
  }

  .personName {
    margin: 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 28pt;
  }
  .personMatching {
    margin: 3px 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 18pt;
  }

  .personAge {
    margin-left: 5px;
    font-size: 19pt;
  }

  /*bis hier*/

  .img {
    max-width: 6rem;
    height: 6rem;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 55%;
    margin-left: 10%;
    font-size: 18pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 10%;
    font-size: 18pt;
  }

  .watchingDiv {
    margin-left: 2.1rem;
    display: flex;
    display: -webkit-flex;
    margin-top: 5.5rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingImg {
    width: 3.5rem;
    height: 3rem;
  }

  .newJourneyImg {
    width: 6rem;
    height: 6rem;
    float: left;
    margin-left: 1rem;
    position: relative;
    margin: 0rem 1rem 1rem 1rem;
  }

  .newJourneyP {
    float: left;
    font-size: 28pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.outterBooking {
  color: #707070;
}

.destinationBookingNew {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
}

.destinationBookingNew img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBookingNew span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.newJourneyBookingDiv {
  display: -webkit-flex;
  display: flex;
  margin-top: -0.5rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: -1rem;
}
.newJourneyBookingImg {
  width: 4rem;
  height: 4rem;
  float: left;
}

.newJourneyBooking {
  float: left;
  font-size: 20pt;
  margin-top: 0.7rem;
  margin-left: 0.5rem;
}

.bookingDiv {
  text-align: center;
  margin-top: -1.5rem;
}

.bookingMaxPriceNew {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBookingNew {
  display: inline-block;
  font-size: 20pt;
}

.payFormBookingNew {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 800px) and (min-height: 800px) {
  .destinationBookingNew img {
    width: 52px;
    height: 60px;
    margin-right: 0.5rem;
    float: left;
  }

  .destinationBookingNew span {
    font-size: 32pt;
    color: #707070;
    float: left;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  .arrivalBookingNew {
    margin-top: 4rem;
    font-size: 21pt;
    margin-left: 4.7rem;
  }

  .newJourneyBookingDiv {
    display: -webkit-flex;
    display: flex;
    margin-top: -0.5rem;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: -1rem;
  }
  .newJourneyBookingImg {
    width: 8rem;
    height: 8rem;
    float: left;
  }

  .newJourneyBooking {
    float: left;
    font-size: 32pt;
    margin-top: 2.2rem;
    margin-left: 0.5rem;
  }

  .bookingMaxPriceNew {
    margin-top: -1.5rem;

    display: inline-block;
    font-size: 20pt;
  }

  .priceBookingNew {
    display: inline-block;
    font-size: 32pt;
  }

  .payFormBookingNew {
    margin-top: -0.5rem;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 0.5;
    font-size: 32pt;
  }

  .buttonBooking {
    width: 60%;
    height: 50pt;
    background-color: #57628d;
    border: none;
    color: white;
    font-size: 20pt;
    cursor: pointer;
    border-radius: 17pt;
    margin-top: 8%;
  }
}

.outterBooking {
  color: #707070;
}

.destinationBooking {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
}

.destinationBooking img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBooking span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.personBookingDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-margin-after: 1.2rem;
          margin-block-end: 1.2rem;
  margin-top: 2rem;
}

.personBookingInfos {
  display: -webkit-flex;
  display: flex;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personBookingName {
  margin: 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 20pt;
}
.personBookingMatching {
  margin: 3px 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 12pt;
}

.personBookingAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

.imgBooking {
  width: 4rem;
  height: 4rem;
}

.bookingDiv {
  text-align: center;
  margin-top: -1.5rem;
}

.bookingMaxPrice {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBooking {
  display: inline-block;
  font-size: 20pt;
}

.payFormBooking {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.outterBooking {
  color: #707070;
}

.destinationBooking {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
}

.destinationBooking img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBooking span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.personBookingDiv {
  display: -webkit-flex;
  display: flex;
  margin-top: 0.5rem;
}

.personBookingDiv2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: normal;
          align-items: normal;
}

.personBookingInfos {
  display: -webkit-flex;
  display: flex;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personBookingName {
  margin: 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 20pt;
}
.personBookingMatching {
  margin: 3px 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 12pt;
}

.personBookingAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

.imgBooking {
  width: 4rem;
  height: 4rem;
}

.watchingBookingDiv {
  margin-left: 0.7rem;
  padding-top: 0.5rem;
  display: -webkit-flex;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  overflow: hidden;
  -webkit-align-items: normal;
          align-items: normal;
}
.watchingBookingImg {
  width: 2.5rem;
  height: 2rem;
}
.watchingBookingProp {
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: -0.2rem;
  margin-left: 1.25rem;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 10pt;
  color: #57628d;
}

.watchingBookingSuggestion {
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: -0.1rem;
  margin-left: 1.25rem;
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  font-size: 10pt;
  color: #57628d;
}

.bookingDiv {
  text-align: center;
  margin-top: 1rem;
}

.bookingMaxPrice {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBooking {
  display: inline-block;
  font-size: 20pt;
}

.payFormBooking {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 436px) {
  .watchingBookingProp {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: 0.4rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingBookingDiv {
    margin-left: 0.7rem;
    padding-top: 0.5rem;
    display: -webkit-flex;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    -webkit-align-items: normal;
            align-items: normal;
  }
}

@media only screen and (max-width: 306px) {
  .watchingBookingProp {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: -0.5rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 388px) {
  .watchingBookingSuggestion {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: -0.6rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 303px) {
  .watchingBookingSuggestion {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: -1.1rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 80%;
            flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingBookingDiv {
    margin-left: 0.7rem;
    padding-top: 0.8rem;
    display: -webkit-flex;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    -webkit-align-items: normal;
            align-items: normal;
  }
}
@media only screen and (min-width: 800px) and (min-height: 800px) {
  .watchingBookingDiv {
    margin-left: 1.6rem;
    padding-top: 0.5rem;
    display: -webkit-flex;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    -webkit-align-items: normal;
            align-items: normal;
  }
  .watchingBookingImg {
    width: 4.5rem;
    height: 3.5rem;
  }
  .watchingBookingProp {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: -0.2rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 70%;
            flex-basis: 70%;
    font-size: 18pt;
    color: #57628d;
  }

  .watchingBookingSuggestion {
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: 0rem;
    margin-left: 1.25rem;
    -webkit-flex-basis: 70%;
            flex-basis: 70%;
    font-size: 18pt;
    color: #57628d;
  }

  .buttonBooking {
    width: 60%;
    height: 60pt;
    background-color: #57628d;
    border: none;
    color: white;
    font-size: 32pt;
    cursor: pointer;
    border-radius: 17pt;
    margin-top: 8%;
  }

  .destinationBooking img {
    width: 52px;
    height: 60px;
    margin-right: 0.5rem;
    float: left;
  }

  .destinationBooking span {
    font-size: 32pt;
    color: #707070;
    float: left;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  .arrivalBooking {
    margin-top: 4rem;
    font-size: 21pt;
    margin-left: 4.7rem;
  }

  .bookingMaxPrice {
    margin-top: -1.5rem;

    display: inline-block;
    font-size: 20pt;
  }

  .priceBooking {
    display: inline-block;
    font-size: 32pt;
  }

  .payFormBooking {
    margin-top: -0.5rem;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 0.5;
    font-size: 32pt;
  }

  .personBookingDiv {
    display: -webkit-flex;
    display: flex;
    margin-top: 0.5rem;
  }

  .personBookingDiv2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: normal;
            align-items: normal;
  }

  .personBookingInfos {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0.5rem;
  }

  .personBookingName {
    margin: 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 32pt;
  }
  .personBookingMatching {
    margin: 3px 0;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    font-size: 22pt;
  }

  .personBookingAge {
    font-size: 12px;
    margin-left: 5px;
    font-size: 23pt;
  }

  .imgBooking {
    width: 8rem;
    height: 8rem;
  }
}

/* .headerApp {
  background-color: #57628d;
  height: 4rem;
  border-style: solid;
  text-align: center;
  color: #818181;
}

.headerApp .headerContent {
  text-align: center;
  font-weight: normal;
  font-size: 32px;
  margin-top: 0.9rem;
  position: sticky;
  color: white;
}

.headerApp .backButton {
  width: 20pt;
  height: 30px;
  float: right;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.headerApp .toProfile {
  width: 44px;
  height: 44px;
  float: right;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
} */

.invisible {
  visibility: hidden;
}

.body {
  background-color: white;
  text-align: center;
  color: #818181;
}

.destination {
  border-style: solid;
  border-color: #dddee0;
  border-radius: 17pt;
  border-width: 0.5pt;
  max-width: 16rem;
  height: 3.5rem;
  margin-left: 3%;
  margin-bottom: 0.5rem;
  width: 100%;
  margin-top: 1rem;
}

.destination img {
  margin-top: 1rem;
  float: left;
  width: 22px;
  height: 32px;
  margin-top: 0.8rem;
  margin-left: 1rem;
}

.destination span {
  margin-top: 0.9rem;
  margin-left: 0.5rem;
  float: left;
  font-size: 20pt;
  color: #707070;
}

@media only screen and (max-width: 272px) {
  .destination span {
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    float: left;
    font-size: 20pt;
    color: #707070;
  }
}

.backgroundConfirmation {
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url(/static/media/taxiBackground.00647f56.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-position: 50% 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.contentConfirmation {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  display: inline-block;
}

.confirmationImg {
  width: 132pt;
  height: 132pt;
}

.confirmationP {
  margin-top: 1rem;
  font-size: 30pt;
  color: white;
  padding-bottom: 5rem;
}

.bottomOutterDiv {
  position: fixed;
  bottom: 0px;
  width: 100%;
  bottom: 1em;
  margin-bottom: 5%;
}

.bottomInnerDiv {
  text-align: center;
  display: inline-block;
}

.confirmationSuggestion {
  color: white;
  font-size: 18pt;
  width: 80%;
  margin-left: 10%;
  font-weight: lighter;
  text-align: center;
}
.toSuggestionImg {
  width: 42pt;
  height: 42pt;
}

@media only screen and (orientation: landscape) and (min-height: 362px) {
  .confirmationSuggestion {
    color: white;
    font-size: 14pt;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1em;
    margin-bottom: 0%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 80pt;
    height: 80pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 22pt;
    color: white;
    padding-bottom: 6rem;
  }
}

@media only screen and (orientation: landscape) and (max-height: 361px) {
  .confirmationSuggestion {
    color: white;
    font-size: 14pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 2%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 64pt;
    height: 64pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 18pt;
    color: white;
    padding-bottom: 4rem;
  }

  .toSuggestionImg {
    width: 28pt;
    height: 28pt;
  }
}

@media only screen and (orientation: landscape) and (max-height: 361px) and (max-width: 560px) {
  .confirmationImg {
    width: 68pt;
    height: 68pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 20pt;
    color: white;
    padding-bottom: 4rem;
  }
}

@media only screen and (orientation: portrait) and (max-width: 239px) {
  .contentConfirmation {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    display: inline-block;
  }

  .confirmationSuggestion {
    color: white;
    font-size: 16pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 1rem;
    max-height: 50%;
  }

  .confirmationImg {
    width: 88pt;
    height: 88pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 26pt;
    color: white;
    padding-bottom: 2rem;
  }

  .toSuggestionImg {
    width: 34pt;
    height: 34pt;
  }
}

@media only screen and (orientation: portrait) and (max-height: 675px) {
  .contentConfirmation {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    display: inline-block;
  }

  .confirmationSuggestion {
    color: white;
    font-size: 16pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 1rem;
    max-height: 50%;
  }

  .confirmationImg {
    width: 88pt;
    height: 88pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 26pt;
    color: white;
    padding-bottom: 2rem;
  }

  .toSuggestionImg {
    width: 34pt;
    height: 34pt;
  }
}

@media only screen and (min-width: 750px) and (min-height: 750px) {
  .confirmationSuggestion {
    color: white;
    font-size: 32pt;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 2%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 150pt;
    height: 150pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 40pt;
    color: white;
    padding-bottom: 4rem;
  }

  .toSuggestionImg {
    width: 62pt;
    height: 62pt;
  }
}

.sliderButton {
  background-color: #57628d;
  width: 52px;
  height: 52px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 50%;
  position: absolute;
  border-radius: 50%;
  top: -26px;
  left: -26px;
  z-index: 5;
}

.slider {
  position: static;
  width: 18px;
  height: 10px;
}

.infos{
  width: 100%;
  position: fixed;
  background-color: white;
  transition: 1s;
  -webkit-transition: 1s; /*Safari*/
  z-index: 2;
  border-top: 4px solid #57628d;
  height: 74%;
}

.hidden {
  top: 90%;
}

.shown {
  top: 20%;
}

.map{
  width: 100%;
  -webkit-transition: 1s; /*Safari*/
  transition: 1s;
  background:
    url(/static/media/Map2.604a9b9b.png);
  position: fixed;
  top: 0px;
  background-size: cover;
  background-position: 50% 15%;
  background-repeat: no-repeat;
  text-align: center;
}
.arrivalTime {
  position: fixed;
  top: 0;
  left: -40px;
  background-color: rgba(57, 62, 141, 0.4);
  width: 200px;
  height: 64px;
  border-radius: 32px
}
.arrivalTimeSpan{
  position: relative;
  font-size: 14pt;
  color: #FFFFFF;
  text-align: left;
  margin: 12px;
  margin-left: 88px
}
.big{
  height: 90%
}
.small{
  height: 20%
}

.container {
  text-align: left;
  color: #707070;
  font-size: 12pt;
  font-weight: lighter;
  width: 90%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 4%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.media {
  width: 100%;
  max-width: 720px;
}

.Timeline {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.MediaCoverContainer {
  width: 200%;
  height: 200px;
}

.SearchMedia {
  width: 60%;
  font-size: 12pt;
  padding: 6pt;
  border: none;
  box-shadow: 3px 3px 6px #AAAAAA, 0 3px 3px 0px #AAAAAA;;
}
.Lens{
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.MediaDescribtion{
  margin-top: 10%;
  height: 200px;
  width: 100%;
}
.coverInfos {
  font-size: 12pt;
  margin-right: 5%;
  float: right;
  text-align: right;
}
.toogleInvitation{
  margin-right: 2rem;
  float: left;
  vertical-align: middle;
}
h1 {
  text-align: center;
  color: #57628d;
  font-size: 20pt;
}

.passengerDiv {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-margin-after: 1.2rem;
          margin-block-end: 1.2rem;
  margin-top: 1rem;
  border-style: solid;
  border-color: #DDDEE0;
  border-width: 0.5pt
}


.passengerInfos {
  display: -webkit-flex;
  display: flex;
  text-align: left;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 0.5rem;
}

.passengerName {
  margin: 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 20pt;
}
.passengerMatching {
  margin: 3px 0;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  font-size: 12pt;
}

.passengerAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

/*bis hier*/

.passengerImg {
  width: 48pt;
  height: 48pt;
}

.MediaPicture {
  float: left;
  width: 126px ;
  height: 175px;
  margin: 1%;
  overflow-y: hidden;
  box-shadow: 5px 5px 3px #AAAAAA, 0 5px 5px 0px #AAAAAA;
}
.MediaPictureImg {
  width: 100%;
  margin-top: -30px
}
.MediaName{
  position: relative;
  top: 147px;
  background: rgba(255,255,255,0.8);
  padding: 5px;
}

.outterProfile {
  color: #707070;
}

.pic {
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.profileImg {
  border: solid;
  border-color: #57628d;
  border-radius: 50%;
  height: 15rem;
}

.topProfileFlexContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: -2rem;
}

.bottomProfileFlexContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.profileTag {
  font-size: 18pt;
  margin-right: 0.5rem;
}

.profileProp {
  font-size: 14pt;
  margin-left: 0.5rem;
  margin-top: 1.5rem;
}

.interests {
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  margin: 10px;
  font-size: 18pt;
  color: #707070;
}

.interestsP {
  margin-left: 1rem;
}

.interestsContainerOutter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  justify-items: center;
  text-align: center;

  -webkit-align-content: center;

          align-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 14pt;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.interestContainer {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  -webkit-flex-basis: 20%;
          flex-basis: 20%;
  text-align: center;
}

.interestImg {
  height: 4rem;
  width: 4rem;
}

.interestTag {
  margin-top: 0.5rem;
}

.addInterest {
  height: 24pt;
  width: 24pt;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 1.7rem;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .pic {
    border-color: #dddee0;
    border-width: 1pt;
    border-style: solid;
    text-align: center;
    margin: 15px;
    padding: 10px;
  }

  .profileImg {
    margin-top: 2rem;
    border: solid;
    border-color: #57628d;
    border-radius: 50%;
    height: 30rem;
  }

  .topProfileFlexContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: -4rem;
  }

  .bottomProfileFlexContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .profileTag {
    font-size: 34pt;
    margin-right: 1rem;
  }

  .profileProp {
    font-size: 28pt;
    margin-left: 1rem;
    margin-top: 2.6rem;
  }

  .interests {
    border-color: #dddee0;
    border-width: 1pt;
    border-style: solid;
    margin: 15px;
    font-size: 34pt;
    color: #707070;
  }

  .interestsP {
    margin-left: 2rem;
  }

  .interestsContainerOutter {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    justify-items: center;
    text-align: center;

    -webkit-align-content: center;

            align-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-size: 28pt;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .interestContainer {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    -webkit-flex-basis: 20%;
            flex-basis: 20%;
    text-align: center;
  }

  .interestImg {
    height: 10rem;
    width: 10rem;
  }

  .interestTag {
    margin-top: 1rem;
  }

  .addInterest {
    height: 60pt;
    width: 60pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 5rem;
  }
}

@media only screen and (orientation: portrait) {
  .mainAccountDiv {
    height: 100vh;
    color: #707070;
  }
  .rightarrow {
    height: 15pt;
    width: 10pt;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 0;
  }

  .upperLinks {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    height: 60%;
  }

  .link {
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }

  .dummyLink {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinks {
    width: 100%;
    bottom: 40px;
    position: fixed;
    width: 100%;
  }
}

@media only screen and (orientation: landscape) {
  .mainAccountDiv {
    height: 100%;
    color: #707070;
  }
  .rightarrow {
    height: 15pt;
    width: 10pt;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 0;
  }

  .upperLinks {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    height: 60%;
  }

  .link {
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .dummyLink {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinksDiv {
    padding-top: 2rem;
    margin-top: 2rem;
    height: 40%;
    width: 100%;
  }

  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinks {
    bottom: 40px;
    width: 100%;
  }
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .mainAccountDiv {
    height: 100vh;
    color: #707070;
    font-size: 32pt;
  }

  .link {
    border-color: #959596;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .rightarrow {
    height: 32px;
    width: 20px;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 7pt;
  }
  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #959596;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }
  .dummyLink {
    background-color: #e2e2e2;
    border-color: #959596;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }
}

.firstService {
  margin-top: 2rem;
  border-width: 1px;
  height: 42pt;
  border-color: #a4a6a8;
  border-width: 1pt;
  float: left;
  width: 100%;
  border-top-style: solid;
  border-bottom-style: solid;
}

.service {
  border-width: 1px;
  height: 42pt;
  border-color: #a4a6a8;
  border-width: 1pt;
  float: left;
  width: 100%;
  border-bottom-style: solid;
}

.serviceString {
  margin-left: 2rem;
  margin-top: 14pt;
  float: left;
}

.toogleService {
  margin-right: 2rem;
  margin-top: 11.5pt;
  float: right;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .firstService {
    margin-top: 2rem;
    border-width: 1px;
    height: 66pt;
    border-color: #a4a6a8;
    border-width: 1pt;
    float: left;
    width: 100%;
    border-top-style: solid;
    border-bottom-style: solid;
  }

  .service {
    border-width: 1px;
    height: 66pt;
    border-color: #a4a6a8;
    border-width: 1pt;
    float: left;
    width: 100%;
    border-bottom-style: solid;
  }

  .serviceString {
    margin-left: 2rem;
    margin-top: 17pt;
    float: left;
  }

  .toogleService {
    margin-right: 2rem;
    margin-top: 16pt;
    float: right;
  }
}

.services {
  color: #707070;
  font-size: 14pt;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .services {
    color: #707070;
    font-size: 32pt;
  }
}

.mainAssistant {
  height: 100vh;
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url(/static/media/taxiBackground.00647f56.png);
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.floatingContent {
  position: absolute;
  overflow: auto;
}

.contentAssistantInner {
  max-height: 100vh;
  width: 80%;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.85;

  overflow: auto;
}

.skipAssistant {
  font-size: 12pt;
  color: white;
  font-weight: lighter;
  text-align: right;
  margin-right: 1rem;
  margin-top: 1rem;
}

.contentAssistantBorder {
  border-style: solid;
  border-radius: 20px;
  border-color: white;
  background: white;
  margin-bottom: 2rem;
}

.topicAssistant {
  font-size: 13pt;
  color: #57628d;
  font-weight: 550;
}

.heartImg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 40pt;
  max-height: 40pt;
}

.assistantText {
  font-size: 12pt;
  color: #57628d;
  font-weight: lighter;
  max-width: 80%;
  margin-left: 10%;
}

.circleDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.circle {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 0.7rem;
  width: 0.7rem;
  border: 0px solid;
  border-radius: 100px;
  background: #bebdbd;
}

.filledCircle {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 0.7rem;
  width: 0.7rem;
  border: 0px solid;
  border-radius: 100px;
  background: #57628d;
}

@media only screen and (max-height: 421px) and (max-width: 820px) {
  .topicAssistant {
    font-size: 13pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 34pt;
    max-height: 34pt;
  }

  .assistantText {
    font-size: 11pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
}

@media only screen and (max-height: 400px) and (max-width: 648px) {
  .topicAssistant {
    font-size: 12pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 30pt;
    max-height: 30pt;
  }

  .assistantText {
    font-size: 11pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }

  .circleDiv {
    margin-top: -1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media only screen and (max-height: 365px) and (max-width: 580px) {
  .topicAssistant {
    font-size: 11pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 28pt;
    max-height: 28pt;
  }

  .assistantText {
    font-size: 9pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
  .circleDiv {
    margin-top: -1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media only screen and (max-height: 616px) and (max-width: 280px) {
  .topicAssistant {
    font-size: 12pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 32pt;
    max-height: 32pt;
  }

  .assistantText {
    font-size: 10pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }
  .circleDiv {
    margin-top: -1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media only screen and (min-height: 750px) and (min-width: 750px) {
  .topicAssistant {
    font-size: 26pt;
    color: #57628d;
    font-weight: 550;
  }

  .heartImg {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    max-width: 52pt;
    max-height: 52pt;
  }

  .assistantText {
    font-size: 22pt;
    color: #57628d;
    font-weight: lighter;
    max-width: 80%;
    margin-left: 10%;
  }

  .skipAssistant {
    font-size: 22pt;
    color: white;
    font-weight: lighter;
    text-align: right;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .circle {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    border: 0px solid;
    border-radius: 100px;
    background: #bebdbd;
  }

  .filledCircle {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    border: 0px solid;
    border-radius: 100px;
    background: #57628d;
  }
}

@media only screen and (orientation: portrait) {
  .welcomeDiv {
    height: 100vh;
    background-image: url(/static/media/LogoscreenPortrait.cd0d9aba.png);
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (orientation: landscape) {
  .welcomeDiv {
    height: 100vh;
    background-image: url(/static/media/LogoscreenLandscape.ecf1e1ac.png);
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

