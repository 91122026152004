.outterBooking {
  color: #707070;
}

.destinationBooking {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: sticky;
}

.destinationBooking img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBooking span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.personBookingDiv {
  display: flex;
  margin-top: 0.5rem;
}

.personBookingDiv2 {
  display: flex;
  align-items: normal;
}

.personBookingInfos {
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personBookingName {
  margin: 0;
  flex-basis: 100%;
  font-size: 20pt;
}
.personBookingMatching {
  margin: 3px 0;
  flex-basis: 100%;
  font-size: 12pt;
}

.personBookingAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

.imgBooking {
  width: 4rem;
  height: 4rem;
}

.watchingBookingDiv {
  margin-left: 0.7rem;
  padding-top: 0.5rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  overflow: hidden;
  align-items: normal;
}
.watchingBookingImg {
  width: 2.5rem;
  height: 2rem;
}
.watchingBookingProp {
  text-align: left;
  align-items: flex-start;
  margin-top: -0.2rem;
  margin-left: 1.25rem;
  flex-basis: 100%;
  font-size: 10pt;
  color: #57628d;
}

.watchingBookingSuggestion {
  text-align: left;
  align-items: flex-start;
  margin-top: -0.1rem;
  margin-left: 1.25rem;
  flex-basis: 80%;
  font-size: 10pt;
  color: #57628d;
}

.bookingDiv {
  text-align: center;
  margin-top: 1rem;
}

.bookingMaxPrice {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBooking {
  display: inline-block;
  font-size: 20pt;
}

.payFormBooking {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 436px) {
  .watchingBookingProp {
    text-align: left;
    align-items: flex-start;
    margin-top: 0.4rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingBookingDiv {
    margin-left: 0.7rem;
    padding-top: 0.5rem;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    align-items: normal;
  }
}

@media only screen and (max-width: 306px) {
  .watchingBookingProp {
    text-align: left;
    align-items: flex-start;
    margin-top: -0.5rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 388px) {
  .watchingBookingSuggestion {
    text-align: left;
    align-items: flex-start;
    margin-top: -0.6rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 303px) {
  .watchingBookingSuggestion {
    text-align: left;
    align-items: flex-start;
    margin-top: -1.1rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingBookingDiv {
    margin-left: 0.7rem;
    padding-top: 0.8rem;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    align-items: normal;
  }
}
@media only screen and (min-width: 800px) and (min-height: 800px) {
  .watchingBookingDiv {
    margin-left: 1.6rem;
    padding-top: 0.5rem;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    align-items: normal;
  }
  .watchingBookingImg {
    width: 4.5rem;
    height: 3.5rem;
  }
  .watchingBookingProp {
    text-align: left;
    align-items: flex-start;
    margin-top: -0.2rem;
    margin-left: 1.25rem;
    flex-basis: 70%;
    font-size: 18pt;
    color: #57628d;
  }

  .watchingBookingSuggestion {
    text-align: left;
    align-items: flex-start;
    margin-top: 0rem;
    margin-left: 1.25rem;
    flex-basis: 70%;
    font-size: 18pt;
    color: #57628d;
  }

  .buttonBooking {
    width: 60%;
    height: 60pt;
    background-color: #57628d;
    border: none;
    color: white;
    font-size: 32pt;
    cursor: pointer;
    border-radius: 17pt;
    margin-top: 8%;
  }

  .destinationBooking img {
    width: 52px;
    height: 60px;
    margin-right: 0.5rem;
    float: left;
  }

  .destinationBooking span {
    font-size: 32pt;
    color: #707070;
    float: left;
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  .arrivalBooking {
    margin-top: 4rem;
    font-size: 21pt;
    margin-left: 4.7rem;
  }

  .bookingMaxPrice {
    margin-top: -1.5rem;

    display: inline-block;
    font-size: 20pt;
  }

  .priceBooking {
    display: inline-block;
    font-size: 32pt;
  }

  .payFormBooking {
    margin-top: -0.5rem;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 0.5;
    font-size: 32pt;
  }

  .personBookingDiv {
    display: flex;
    margin-top: 0.5rem;
  }

  .personBookingDiv2 {
    display: flex;
    align-items: normal;
  }

  .personBookingInfos {
    display: flex;
    text-align: left;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 0.5rem;
  }

  .personBookingName {
    margin: 0;
    flex-basis: 100%;
    font-size: 32pt;
  }
  .personBookingMatching {
    margin: 3px 0;
    flex-basis: 100%;
    font-size: 22pt;
  }

  .personBookingAge {
    font-size: 12px;
    margin-left: 5px;
    font-size: 23pt;
  }

  .imgBooking {
    width: 8rem;
    height: 8rem;
  }
}
