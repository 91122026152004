@media only screen and (orientation: portrait) {
  .mainAccountDiv {
    height: 100vh;
    color: #707070;
  }
  .rightarrow {
    height: 15pt;
    width: 10pt;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 0;
  }

  .upperLinks {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    height: 60%;
  }

  .link {
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }

  .dummyLink {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinks {
    width: 100%;
    bottom: 40px;
    position: fixed;
    width: 100%;
  }
}

@media only screen and (orientation: landscape) {
  .mainAccountDiv {
    height: 100%;
    color: #707070;
  }
  .rightarrow {
    height: 15pt;
    width: 10pt;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 0;
  }

  .upperLinks {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    height: 60%;
  }

  .link {
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .dummyLink {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinksDiv {
    padding-top: 2rem;
    margin-top: 2rem;
    height: 40%;
    width: 100%;
  }

  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #a4a6a8;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }

  .lowerLinks {
    bottom: 40px;
    width: 100%;
  }
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .mainAccountDiv {
    height: 100vh;
    color: #707070;
    font-size: 32pt;
  }

  .link {
    border-color: #959596;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }

  .rightarrow {
    height: 32px;
    width: 20px;
    float: right;
    position: relative;
    top: 50%;
    margin-top: 7pt;
  }
  .dummyLinkTop {
    background-color: #e2e2e2;
    border-color: #959596;
    border-width: 0.5pt;
    border-top-style: solid;
    border-bottom-style: solid;

    padding: 20px;
    padding-left: 40px;
  }
  .dummyLink {
    background-color: #e2e2e2;
    border-color: #959596;
    border-width: 0.5pt;
    border-bottom-style: solid;
    padding: 20px;
    padding-left: 40px;
  }
}
