@media only screen and (orientation: portrait) {
  .welcomeDiv {
    height: 100vh;
    background-image: url("../assets/LogoscreenPortrait.png");
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (orientation: landscape) {
  .welcomeDiv {
    height: 100vh;
    background-image: url("../assets/LogoscreenLandscape.png");
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
