.outterBooking {
  color: #707070;
}

.destinationBooking {
  display: inline;
  margin-left: 1rem;
  text-align: left;
  position: sticky;
}

.destinationBooking img {
  width: 22px;
  height: 32px;
  margin-right: 0.5rem;
  float: left;
}

.destinationBooking span {
  font-size: 20pt;
  color: #707070;
  float: left;
}

.arrivalBooking {
  margin-left: 2rem;
}

.personBookingDiv {
  display: flex;
  margin-block-end: 1.2rem;
  margin-top: 2rem;
}

.personBookingInfos {
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personBookingName {
  margin: 0;
  flex-basis: 100%;
  font-size: 20pt;
}
.personBookingMatching {
  margin: 3px 0;
  flex-basis: 100%;
  font-size: 12pt;
}

.personBookingAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

.imgBooking {
  width: 4rem;
  height: 4rem;
}

.bookingDiv {
  text-align: center;
  margin-top: -1.5rem;
}

.bookingMaxPrice {
  margin-top: -1.5rem;

  display: inline-block;
  font-size: 12pt;
}

.priceBooking {
  display: inline-block;
  font-size: 20pt;
}

.payFormBooking {
  margin-top: -0.5rem;
  width: 100%;
  text-align: center;
  float: left;
  line-height: 0.5;
  font-size: 15pt;
}

.alignCenterDiv {
  text-align: center;
}
.buttonBooking {
  width: 60%;
  height: 50pt;
  background-color: #57628d;
  border: none;
  color: white;
  font-size: 20pt;
  cursor: pointer;
  border-radius: 17pt;
  margin-top: 8%;
}

.buttonBooking:hover,
.buttonBooking:focus {
  background: #4e4f50;
}

.buttonBooking:active {
  transform: scale(0.8);
}

.swal-button {
  background-color: #57628d;
  font-size: 15px;
  border: 0.5px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
