.services {
  color: #707070;
  font-size: 14pt;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
  .services {
    color: #707070;
    font-size: 32pt;
  }
}
