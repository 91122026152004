.passengerDiv {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 8px;
  display: flex;
  margin-block-end: 1.2rem;
  margin-top: 1rem;
  border-style: solid;
  border-color: #DDDEE0;
  border-width: 0.5pt
}


.passengerInfos {
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.passengerName {
  margin: 0;
  flex-basis: 100%;
  font-size: 20pt;
}
.passengerMatching {
  margin: 3px 0;
  flex-basis: 100%;
  font-size: 12pt;
}

.passengerAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

/*bis hier*/

.passengerImg {
  width: 48pt;
  height: 48pt;
}
