.backgroundConfirmation {
  background: -webkit-linear-gradient(
      rgba(124, 135, 181, 0.75),
      rgba(124, 135, 181, 0.75)
    ),
    url("../assets/taxiBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  background-position: 50% 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.contentConfirmation {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  display: inline-block;
}

.confirmationImg {
  width: 132pt;
  height: 132pt;
}

.confirmationP {
  margin-top: 1rem;
  font-size: 30pt;
  color: white;
  padding-bottom: 5rem;
}

.bottomOutterDiv {
  position: fixed;
  bottom: 0px;
  width: 100%;
  bottom: 1em;
  margin-bottom: 5%;
}

.bottomInnerDiv {
  text-align: center;
  display: inline-block;
}

.confirmationSuggestion {
  color: white;
  font-size: 18pt;
  width: 80%;
  margin-left: 10%;
  font-weight: lighter;
  text-align: center;
}
.toSuggestionImg {
  width: 42pt;
  height: 42pt;
}

@media only screen and (orientation: landscape) and (min-height: 362px) {
  .confirmationSuggestion {
    color: white;
    font-size: 14pt;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1em;
    margin-bottom: 0%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 80pt;
    height: 80pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 22pt;
    color: white;
    padding-bottom: 6rem;
  }
}

@media only screen and (orientation: landscape) and (max-height: 361px) {
  .confirmationSuggestion {
    color: white;
    font-size: 14pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 2%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 64pt;
    height: 64pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 18pt;
    color: white;
    padding-bottom: 4rem;
  }

  .toSuggestionImg {
    width: 28pt;
    height: 28pt;
  }
}

@media only screen and (orientation: landscape) and (max-height: 361px) and (max-width: 560px) {
  .confirmationImg {
    width: 68pt;
    height: 68pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 20pt;
    color: white;
    padding-bottom: 4rem;
  }
}

@media only screen and (orientation: portrait) and (max-width: 239px) {
  .contentConfirmation {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    display: inline-block;
  }

  .confirmationSuggestion {
    color: white;
    font-size: 16pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 1rem;
    max-height: 50%;
  }

  .confirmationImg {
    width: 88pt;
    height: 88pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 26pt;
    color: white;
    padding-bottom: 2rem;
  }

  .toSuggestionImg {
    width: 34pt;
    height: 34pt;
  }
}

@media only screen and (orientation: portrait) and (max-height: 675px) {
  .contentConfirmation {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    display: inline-block;
  }

  .confirmationSuggestion {
    color: white;
    font-size: 16pt;
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 1rem;
    max-height: 50%;
  }

  .confirmationImg {
    width: 88pt;
    height: 88pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 26pt;
    color: white;
    padding-bottom: 2rem;
  }

  .toSuggestionImg {
    width: 34pt;
    height: 34pt;
  }
}

@media only screen and (min-width: 750px) and (min-height: 750px) {
  .confirmationSuggestion {
    color: white;
    font-size: 32pt;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 2%;
    font-weight: lighter;
    text-align: center;
  }
  .bottomOutterDiv {
    position: fixed;
    bottom: 0px;
    width: 100%;
    bottom: 1%;
    margin-bottom: 2%;
    max-height: 50%;
  }

  .confirmationImg {
    width: 150pt;
    height: 150pt;
  }

  .confirmationP {
    margin-top: 0.5rem;
    font-size: 40pt;
    color: white;
    padding-bottom: 4rem;
  }

  .toSuggestionImg {
    width: 62pt;
    height: 62pt;
  }
}
