.outter {
  float: left;
  border-color: #dddee0;
  border-width: 0.5pt;
  border-style: solid;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 94%;
  cursor: pointer;
}

.outter:hover,
.outter:focus {
  background: #e9edf1;
}

.arrival {
  text-align: left;
  float: left;
  width: 65%;
  margin-left: 5%;
  font-size: 12pt;
}

.price {
  float: right;
  text-align: right;
  width: 25%;
  margin-right: 5%;
  font-size: 12pt;
}
/*von hier*/

.personDiv {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}
.personDiv_2 {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 1rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}

.personDiv2 {
  margin-left: 1rem;
  display: flex;
  display: -webkit-flex;
  align-items: normal;
  -webkit-align-items: normal;

  overflow: hidden;
  margin-bottom: 1rem;
}

.personInfos {
  display: flex;
  display: -webkit-flex;
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.personName {
  margin: 0;
  flex-basis: 100%;
  font-size: 19pt;
}
.personMatching {
  margin: 3px 0;
  flex-basis: 100%;
  font-size: 12pt;
}

.personAge {
  font-size: 12px;
  margin-left: 5px;
  font-size: 12pt;
}

/*bis hier*/

.img {
  max-width: 4rem;
  height: 4rem;
}

.img_2 {
  width: 48pt;
  height: 48pt;
  float: left;
  margin-top: 5%;
  margin-left: 5%;
}

.watchingDiv {
  margin-left: 1.75rem;
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0.6rem;
  margin-top: 4rem;
  overflow: hidden;
  align-items: normal;
  -webkit-align-items: normal;
}

.watchingImg {
  width: 2.5rem;
  height: 2rem;
}
.watchingProp {
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  margin-top: 0rem;
  margin-left: 1.25rem;
  flex-basis: 100%;
  font-size: 10pt;
  color: #57628d;
}

.watchingSuggestion {
  text-align: left;
  align-items: flex-start;
  -webkit-align-items: flex-start;

  margin-top: 0rem;
  margin-left: 1.25rem;
  flex-basis: 90%;
  font-size: 10pt;
  color: #57628d;
}

.newJourneyImg {
  width: 4rem;
  height: 4rem;
  float: left;
  margin-left: 1rem;
  position: relative;
  margin: 0rem 1rem 1rem 1rem;
}

.newJourneyP {
  float: left;
  font-size: 20pt;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 357px) {
  .watchingDiv {
    margin-left: 1.75rem;
    display: flex;
    display: -webkit-flex;
    padding-top: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: -0.4rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }

  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.6rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 8pt;
    color: #57628d;
  }

  .personInfos {
    display: flex;
    display: -webkit-flex;
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    flex-wrap: wrap;
    margin-left: 0.5rem;
    margin-top: 0.3rem;
  }
}
@media only screen and (min-width: 627px) {
  .watchingDiv {
    margin-left: 1.75rem;
    display: flex;
    display: -webkit-flex;
    padding-top: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.4rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 10pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}
@media only screen and (min-width: 405px) {
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }
}

@media only screen and (max-width: 336px) {
  .watchingImg {
    width: 2.5rem;
    height: 2rem;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 10pt;
    color: #57628d;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 65%;
    margin-left: 5%;
    font-size: 10pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 5%;
    font-size: 10pt;
  }
}

@media only screen and (min-width: 750px) and (min-height: 720px) {
  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: -0.2rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 18pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 18pt;
    color: #57628d;
  }

  .personName {
    margin: 0;
    flex-basis: 100%;
    font-size: 28pt;
  }
  .personMatching {
    margin: 3px 0;
    flex-basis: 100%;
    font-size: 18pt;
  }

  .personAge {
    margin-left: 5px;
    font-size: 19pt;
  }

  /*bis hier*/

  .img {
    max-width: 6rem;
    height: 6rem;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 55%;
    margin-left: 10%;
    font-size: 18pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 10%;
    font-size: 18pt;
  }

  .watchingDiv {
    margin-left: 2.1rem;
    display: flex;
    display: -webkit-flex;
    margin-top: 5.5rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingImg {
    width: 3.5rem;
    height: 3rem;
  }

  .newJourneyImg {
    width: 6rem;
    height: 6rem;
    float: left;
    margin-left: 1rem;
    position: relative;
    margin: 0rem 1rem 1rem 1rem;
  }

  .newJourneyP {
    float: left;
    font-size: 28pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1360px) and (min-height: 1020px) {
  .watchingSuggestion {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    flex-basis: 80%;
    font-size: 18pt;
    color: #57628d;
  }
  .watchingProp {
    text-align: left;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    margin-top: 0.5rem;
    margin-left: 1.25rem;
    flex-basis: 100%;
    font-size: 18pt;
    color: #57628d;
  }

  .personName {
    margin: 0;
    flex-basis: 100%;
    font-size: 28pt;
  }
  .personMatching {
    margin: 3px 0;
    flex-basis: 100%;
    font-size: 18pt;
  }

  .personAge {
    margin-left: 5px;
    font-size: 19pt;
  }

  /*bis hier*/

  .img {
    max-width: 6rem;
    height: 6rem;
  }

  .arrival {
    text-align: left;
    float: left;
    width: 55%;
    margin-left: 10%;
    font-size: 18pt;
  }

  .price {
    float: right;
    text-align: right;
    width: 25%;
    margin-right: 10%;
    font-size: 18pt;
  }

  .watchingDiv {
    margin-left: 2.1rem;
    display: flex;
    display: -webkit-flex;
    margin-top: 5.5rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    align-items: normal;
    -webkit-align-items: normal;
  }

  .watchingImg {
    width: 3.5rem;
    height: 3rem;
  }

  .newJourneyImg {
    width: 6rem;
    height: 6rem;
    float: left;
    margin-left: 1rem;
    position: relative;
    margin: 0rem 1rem 1rem 1rem;
  }

  .newJourneyP {
    float: left;
    font-size: 28pt;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
