.sliderButton {
  background-color: #57628d;
  width: 52px;
  height: 52px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 50%;
  position: absolute;
  border-radius: 50%;
  top: -26px;
  left: -26px;
  z-index: 5;
}

.slider {
  position: static;
  width: 18px;
  height: 10px;
}

.infos{
  width: 100%;
  position: fixed;
  background-color: white;
  transition: 1s;
  -webkit-transition: 1s; /*Safari*/
  z-index: 2;
  border-top: 4px solid #57628d;
  height: 74%;
}

.hidden {
  top: 90%;
}

.shown {
  top: 20%;
}
